import React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { HeroBanner } from "../components/hero-banner";
import { ContentFade } from "../components/content-fade";
import { IFluidImage, IChildImageSharpFluid } from "../interfaces";
import { Banner } from "../components/banner";
import { Wrapper } from "../components/wrapper";
import { colors, mediaQuery } from "../utilities";
import GatsbyImage from "gatsby-image";
import { PersonCard } from "../components/person-card";
import { FlexBlocks, FlexBlock } from "../components/flex-blocks";
import { P, PSize, PControl } from "../components/typography/p";
import { DangerouslySetInnerHtml } from "../components/dangerously-set-inner-html";

export interface ITeamPageProps {
  data: ITeamPageQuery;
}

const Feedback = styled(PControl)({
  fontStyle: "italic",
  p: {
    color: colors.orange.base,
  },
});

const FlexBlockPadded = styled(FlexBlock)({
  paddingLeft: 16,
  paddingRight: 16,
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-child": {
    paddingRight: 0,
  },
});

const QuoteBlock = styled.div({
  borderTop: `4px solid ${colors.grey.base}`,
  borderBottom: `4px solid ${colors.grey.base}`,
  textAlign: "center",
  padding: 16,
  marginTop: 32,
  marginBottom: 32,
  p: {
    margin: 0,
  },
  [mediaQuery[2]]: {
    padding: `32px 180px`,
  },
});

const PersonPadding = styled.div({
  paddingTop: 16,
  paddingBottom: 16,
});

const SecondPage: React.SFC<ITeamPageProps> = ({ data }) => {
  const highlightedTeam = data.content.frontmatter.teamMembers.slice(0, 2);
  const otherTeam = data.content.frontmatter.teamMembers.slice(2);
  return (
    <Layout>
      <SEO title="Team" />
      <HeroBanner
        backgroundImageSrc={
          data.content.frontmatter.heroImg.childImageSharp.fluid.src
        }
        title={data.content.frontmatter.title}
      />
      <Banner />
      <ContentFade
        imgSrc={data.content.frontmatter.offerImg.childImageSharp.fluid.src}
      >
        <h3>{data.content.frontmatter.offerTitle}</h3>
        <P>{data.content.frontmatter.offerContent}</P>
      </ContentFade>
      <Wrapper css={{ paddingTop: 64 }}>
        <GatsbyImage
          fluid={data.content.frontmatter.johnAvatar.childImageSharp.fluid}
          css={{ maxWidth: 400, marginBottom: 16 }}
        />
        <h3>{data.content.frontmatter.johnName}</h3>
        <PControl>
          <DangerouslySetInnerHtml>
            {data.content.frontmatter.mdJohnBio}
          </DangerouslySetInnerHtml>
        </PControl>
        <Feedback>
          <DangerouslySetInnerHtml>
            {data.content.frontmatter.mdJohnsFeedback}
          </DangerouslySetInnerHtml>
        </Feedback>
        <QuoteBlock>
          <P size={PSize.large}>{data.content.frontmatter.quote}</P>
        </QuoteBlock>
        {highlightedTeam.map((ht, i) => (
          <PersonPadding key={i}>
            <PersonCard name={ht.name} fluidImage={ht.avatar} stacked>
              <PControl>
                <DangerouslySetInnerHtml>{ht.mdBio}</DangerouslySetInnerHtml>
              </PControl>
            </PersonCard>
          </PersonPadding>
        ))}

        <PersonPadding>
          <FlexBlocks>
            {otherTeam.map((ot, i) => (
              <FlexBlockPadded key={i}>
                <PersonCard name={ot.name} fluidImage={ot.avatar}>
                  <PControl>
                    <DangerouslySetInnerHtml>
                      {ot.mdBio}
                    </DangerouslySetInnerHtml>
                  </PControl>
                </PersonCard>
              </FlexBlockPadded>
            ))}
          </FlexBlocks>
        </PersonPadding>
      </Wrapper>
    </Layout>
  );
};

export default SecondPage;

interface ITeamPageQuery {
  content: {
    frontmatter: {
      title: string;
      heroImg: IChildImageSharpFluid;
      offerImg: IChildImageSharpFluid;
      offerTitle: string;
      offerContent: string;
      johnName: string;
      johnAvatar: IChildImageSharpFluid;
      mdJohnBio: string;
      mdJohnsFeedback: string;
      quote: string;
      teamMembers: {
        avatar: IChildImageSharpFluid;
        mdBio: string;
        name: string;
      }[];
    };
  };
}

export const query = graphql`
  query TeamPageQuery {
    content: markdownRemark(frontmatter: { templateKey: { eq: "team-page" } }) {
      frontmatter {
        title
        heroImg {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        offerTitle
        offerContent
        offerImg {
          childImageSharp {
            fluid(maxWidth: 500, quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        johnAvatar {
          childImageSharp {
            fluid(maxWidth: 500, quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        johnName
        mdJohnBio
        mdJohnsFeedback
        quote
        teamMembers {
          avatar {
            childImageSharp {
              fluid(maxWidth: 500, quality: 75) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          mdBio
          name
        }
      }
    }
  }
`;
